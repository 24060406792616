import React from 'react';
import './App.css';
import DropZone from './DropZone';
import SetUploader from './SetUploader';
import worker from './worker.js';
import WebWorker from './workerSetup';
import ProcessingCube from './ProcessingCube';
import { timingSafeEqual } from 'crypto';
import Visualizer from './Visualizer';
import Account from './Account';
import LoginDialog from './LoginDialog';
import DeviceLinkDialog from './DeviceLinkDialog';
import queryString from 'query-string';
import qrcode from 'qrcode';

class App extends React.Component {
  worker = null;

  state = {
    sets: {},
    currentState: 'drop', // drop, analyzing, running, empty
    setsDone: [],
    setsErrored: [],
    qrCode: null,
    activeQrSet: null,
    qrLink: null,
    user: null,
    loginError: null,
    loginShown: false,
    linkedMhdClient: null,
    deviceLinkShown: false,
    deviceLinkVerifying: false,
    cloudLinkCode: null,
    linkedSet: null
  }

  componentWillMount() {
    this.validateLocalToken();

    let params = queryString.parse(window.location.search);

    if ("linkedSet" in params) {
      qrcode.toDataURL(params.linkedSet, { scale: 8, errorCorrectionLevel: 'H' }, (err, url) => {
        this.setState({linkedQrCode: url});
      });

      const cloudLink = params.linkedSetCloudLink;
      this.setState({linkedSet: params.linkedSet, linkedSetCloudLink: cloudLink}, () => {
        fetch(cloudLink)
        .then(r => r.json())
        .then(j => {
          const metaUrl = cloudLink.slice(0, cloudLink.indexOf('.com/') + 4) + j.url + 'meta';
          const id = cloudLink.slice(cloudLink.lastIndexOf('/') + 1);
          fetch(metaUrl)
          .then(r => r.text())
          .then(r => {
            const intProperties = ['BitsAllocated', 'width', 'height', 'depth'];
            const metadata = {};
            r.split("\n").forEach(line => {
              const keyValue = line.split("=");
              metadata[keyValue[0]] = intProperties.indexOf(keyValue[0]) !== -1 ? parseInt(keyValue[1]) : keyValue[1];
            });
            const sets = this.state.sets;
            sets[id] = {
              uploadUrl: cloudLink,
              metaData: metadata
            };
            const setsDone = this.state.setsDone;
            setsDone.push(id);
            this.setState({sets, setsDone});
            this.setState({activeQrSet: id})
            this.propagateDeviceLinkSet(sets[id]);
          });
        });
      });
    }

    let deviceLinkSocket = this.props.deviceLinkSocket;

    deviceLinkSocket.on('msg', (data) => {
      switch (data.type) {
        case 'roomclients':
          const linkedMhdClient = data.clients.find((c) => (c.name || '').indexOf('mhd-client') !== -1);
          const newlyLinked = linkedMhdClient && !this.state.linkedMhdClient;
          
          this.setState({linkedMhdClient, deviceLinkVerifying: false}, () => {
            if (newlyLinked) {
              this.state.setsDone.forEach((setId) => {
                if (this.state.sets[setId]) {
                  this.propagateDeviceLinkSet(this.state.sets[setId]);
                }
              });
            }
          });
          break;
        default:
          console.log("Other data type", data.type);
      }
    });

    if (localStorage.getItem('cloudlink')) {
      this.linkDevice(localStorage.getItem('cloudlink'));
    }
  }

  linkDevice(code) {
    this.setState({deviceLinkVerifying: true, linkedMhdClient: null, cloudLinkCode: code});
    this.props.deviceLinkSocket.emit('leave room');
    this.props.deviceLinkSocket.emit('user info', {
      room: 'cloud-' + code,
      name: 'browser-uploader'
    });
    this.props.deviceLinkSocket.emit('roomclients', 'cloud-' + code);
    localStorage.setItem('cloudlink', code);
  }

  unlinkDevice() {
    this.setState({deviceLinkVerifying: false, linkedMhdClient: null, cloudLinkCode: null});
    localStorage.removeItem('cloudlink');
    this.props.deviceLinkSocket.emit('leave room');
  }

  propagateDeviceLinkSet(set) {
    if (this.state.linkedMhdClient) {
      this.props.deviceLinkSocket.emit('control', {
        type: 'set',
        metadata: set.metaData,
        url: set.uploadUrl
      });
    }
  }

  resetWorker() {
    if (this.worker !== null) {
      this.worker.terminate();
      this.worker = null;
    }
  }

  itemsDropped(items) {
    this.reset();

    this.setState({
      currentState: 'analyzing',
      analyzeData: {leftFiles: items.length},
      timer: setInterval(() => {
        this.setState({analyzeData: this.state.analyzeData});
      }, 500)
    });

    console.time("Worker start");
    this.worker = new WebWorker(worker);
    console.timeEnd("Worker start");

    this.worker.addEventListener('message', e => {
      const m = e.data;
      console.log(m);
      if ("set" in m) {
        const newSets = Object.assign(this.state.sets);
        newSets[m.set] = {data: m.imageData, rawImageData: m.rawImageData, gzip: m.gzip, metaData: m.metaData, length: m.length};

        this.setState({sets: newSets, currentSetId: m.set});
      }
      else if ("invalids" in m) {
        clearInterval(this.state.timer);

        if (Object.keys(this.state.sets).length === 0) {
          this.setState({
            currentState: 'empty'
          });
        }
        else {
          this.setState({
            currentState: 'running'
          });
        }
      }
      else if ("stat" in m) {
        this.setState({analyzeData: m.stat});
      }
    });

    this.worker.postMessage({items: items});
  }

  reset() {
    if (this.state.timer) clearInterval(this.state.timer);
    this.resetWorker();
    this.setState({
      sets: {},
      currentState: 'drop',
      setsDone: [],
      setsErrored: [],
      qrCode: null,
      analyzeData: null,
      timer: null
    });
  }

  setDone(id, state) {
    if (this.state.setsDone.indexOf(id) === -1) {
      this.state.sets[id].uploadUrl = state.uploadLink;
      this.setState({
        setsDone: this.state.setsDone.concat([id]),
        sets: this.state.sets
      });
      this.propagateDeviceLinkSet(this.state.sets[id]);
    }
  }

  setErrored(id) {
    if (this.state.setsErrored.indexOf(id) === -1) {
      this.setState({
        setsErrored: this.state.setsErrored.concat([id])
      });
    }
  }

  suppress(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  showQRCode(setId, qrCode, qrLink) {
    this.setState({qrCode, qrLink, activeQrSet: setId});
  }

  showLoginDialog() {
    this.setState({loginShown: true});
  }

  showDeviceLink() {
    this.setState({deviceLinkShown: true});
  }

  hideDeviceLink() {
    this.setState({deviceLinkShown: false});
  }

  logout() {
    this.setState({user: null});
    localStorage.removeItem('userid');
    localStorage.removeItem('token');
  }

  validateLocalToken() {
    if (!localStorage.getItem('userid')) return;

    const userId = localStorage.getItem('userid');
    const token = localStorage.getItem('token');
    const userUrl = `https://cloud.pro.medicalholodeck.com/api/Users/${userId}?access_token=${token}`;

    fetch(userUrl)
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        if (response.error.statusCode == 401) {
          this.logout();
        }
      }
      else {
        this.setState({
          loginShown: false,
          loginError: null,
          user: {
            username: response.username,
            userid: userId,
            token: token
          }
        });
      }
    });
  }

  login(username, password) {
    fetch('https://cloud.pro.medicalholodeck.com/api/Users/login?include=user', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({'email': username, password: password})
    })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        if (response.error.statusCode == 401) {
          this.setState({
            user: null,
            loginError: "Your credentials are not valid."
          });
        }
        else {
          this.setState({
            user: null,
            loginError: response.error.message
          });
        }
      }
      else {
        this.setState({
          loginShown: false,
          loginError: null,
          user: {
            username: response.user.username,
            userid: response.user.id,
            token: response.id
          }
        });
        localStorage.setItem('userid', response.user.id);
        localStorage.setItem('token', response.id);
      }
    })
    .catch(e => {
      this.setState({loginError: "A technical problem occured. Please try again."});
      console.error(e);
    });
  }

  render() {
    const setIds = Object.keys(this.state.sets);
    const setUploaders = setIds.map(id => {
      return (<SetUploader id={id} key={id}
        user={this.state.user}
        linkedMhdClient={this.state.linkedMhdClient}
        endPointUpload='https://dva.cloudxr.medicalholodeck.com/upload'
        endPointGetUploadId='https://dva.cloudxr.medicalholodeck.com/getuploadid'
        onOpenClick={(qrcode, link) => this.showQRCode(id, qrcode, link)}
        onDone={(state) => this.setDone(id, state)}
        onError={() => this.setErrored(id)}
        set={this.state.sets[id]} />);
    });
    const setCount = setIds.length;
    const allDone = this.state.setsErrored.length + this.state.setsDone.length >= setCount;
    const cancel = this.state.currentState === 'running' && !allDone;
    const activeQrSet = (this.state.sets[this.state.activeQrSet] || null);

    const analyzeData = this.state.analyzeData;

    const inFrame = window.location !== window.parent.location && !this.state.linkedSet;

    return (
      <div className={"App" + (inFrame ? ' inframe' : '') + (this.state.linkedSet ? ' linkedset' : '')} onDrag={this.suppress} onDragStart={this.suppress} onDragOver={this.suppress} onDragEnter={this.suppress} onDragLeave={this.suppress} onDragEnd={this.suppress}  onDrop={this.suppress}>
        <header className="App-header">
          {!inFrame && <img style={{display:'block', margin: '2vh auto', maxHeight: '50px', height:'6vh'}} src="mhd-model.svg" />}
          <h1>Medical Image Upload</h1>
          {/*<Account
            user={this.state.user}
            onLogin={() => this.showLoginDialog()}
            onLogout={() => this.logout()}
          />*/}
        </header>
        {!this.state.linkedSet && <div className="instructions">
            <p>Drag medical imaging data in DICOM format to use on your mobile device below. Your data will be automatically anonymized on your computer before the upload. You can upload several folders at the same time.</p>
            <p>After processing and uploading, scan the QR code with the mobile Medical Imaging XR app or connect your VR device with Device Link (Beta).<br/>
  Read our full data protection information <a href="https://medicalholodeck.com/terms/privacy-policy/index.html" target="_blank">here</a>.</p>
        </div>}
        {this.state.linkedSet && <div className="instructions">
          <p>To view this dataset in Medical Imaging XR either scan the code below or open this website on your device.</p>
        </div>}
        {!this.state.linkedSet && <div id="main" className="App-body">
          {this.state.currentState === 'drop' && <div><DropZone itemsDropped={(files) => this.itemsDropped(files)} /></div>}
          {this.state.currentState === 'running' && <div>
            {setUploaders}
            <div>{cancel && <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>Cancel</button>}
            {allDone && <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>New Upload</button>}</div>
          </div>}
          {this.state.currentState === 'empty' && <div>
            <div>
            <h2>No DICOM sets found</h2>
            <p>Please select folders containing <strong>DICOM files</strong>.</p>
            <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>New Upload</button></div></div>}
          {this.state.currentState === 'analyzing' && <div><div>
            {analyzeData &&
              (analyzeData.leftFiles && <h2>Analyzing {analyzeData.leftFiles} File{analyzeData.leftFiles !== 1 ? 's' : ''} on your computer</h2> ||
              (analyzeData.leftSets && <h2>Anonymizing data on your computer</h2>))}
            {analyzeData && analyzeData.leftSets && <h3>Building set #{analyzeData.leftSets}<span style={{opacity: (new Date().getSeconds() % 2 ? 0 : 1)}}>.</span></h3>}
            {(setCount === 0 || !this.state.sets[this.state.currentSetId]) && <ProcessingCube />}
            {(setCount > 0) && this.state.sets[this.state.currentSetId] && <div style={{margin:'auto', maxWidth:'20vw', display: 'flex', justifyContent: 'center', height:'20vh'}}>
              <Visualizer setId={this.state.currentSetId} set={this.state.sets[this.state.currentSetId]} />
            </div>}
            <button style={{marginTop: '5vh'}} onClick={() => this.reset()}>Cancel</button></div></div>}
        </div>}
        {this.state.linkedSet && <p className="id" style={{maxWidth: '25em', whiteSpace: 'normal', margin:'auto', marginBottom: '15px', fontSize:'16px'}}>{activeQrSet ? [activeQrSet.metaData.BodyPart, activeQrSet.metaData.Modality, activeQrSet.metaData.depth + ' slices'].filter(d => !!d).join(" – ") : ''}</p>}
        {this.state.linkedSet && <div id="main" style={{position:'relative', margin:'auto'}} className="App-body">
          <img src={this.state.linkedQrCode} style={{maxHeight:'40vh', margin:'auto'}}/>
          <div style={{
            position: 'absolute',
            width: '15%',
            height: 0,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(23, 33, 58, 1)',
            padding: '16% 1% 1% 1%',
            margin: 'auto',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '100%',
          }}>
            <img src="mhd-model.svg" style={{position:'absolute', paddingTop: '1%', top: 0, left: 0, right:0, bottom:0}}/>
          </div>
        </div>}
        {this.state.linkedSet && <p style={{marginBottom:'1em'}}>or</p>}
        <button style={{marginBottom: '1em'}} className={"devicelinkbutton" + (this.state.linkedMhdClient ? ' linked' : '')} onClick={() => this.showDeviceLink()}>
          <img style={{display:'inline-block', marginRight:'5px', height:'1.5em', verticalAlign:'middle'}} src="device-link.svg" /> {this.state.linkedMhdClient ? 'Linked (' + this.state.linkedMhdClient.name.replace('mhd-client', '') + ')' : 'Device Link (Beta)'}
        </button>
        <div className="App-footer">
          <div className="appstore-links" style={{display:'flex'}}>
            <a href="https://apps.apple.com/us/app/dicom-viewer-ar/id1477475516" target="blank"><img src="appstore-downloadpage-01-01-01.png" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.medicalholodeck.dicomviewerar" target="blank"><img src="google-play-badge.png" /></a>
          </div>
          {!inFrame && <p><a href="https://medicalholodeck.com" target="_blank">Medicalholodeck.com</a></p>}
        </div>
        <div className={"qrwindow overlay" + (this.state.qrCode ? ' shown' : '')} onClick={() => this.setState({qrCode: null})}>
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            <h3>Open DICOM set in Medical Imaging XR</h3>
            <p><strong>{(this.state.sets[this.state.activeQrSet] || {}).length} slice{(this.state.sets[this.state.activeQrSet] || {}).length === 1 ? '' : 's'}</strong></p>
            <p className="id" style={{maxWidth: '25em', whiteSpace: 'normal', margin:'auto'}}>{activeQrSet ? [this.state.activeQrSet, activeQrSet.metaData.BodyPart, activeQrSet.metaData.Modality].filter(d => !!d).join(" – ") : ''}</p>
            <p style={{maxWidth: '20em', marginLeft: 'auto', marginRight: 'auto'}}>Proceed by scanning this QR-code with your mobile device:</p>
            <p><img src={this.state.qrCode} style={{maxWidth:'100%'}}/></p>
            <p>or open this link on your device:</p>
            <p className="link"><a href={this.state.qrLink} target="_blank">{this.state.qrLink}</a></p>
            <button onClick={(e) => { e.stopPropagation(); this.setState({qrCode: null}); }}>Close</button>
          </div>
        </div>
        {<div className={"overlay" + (this.state.deviceLinkShown ? ' shown' : '')}>
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            <DeviceLinkDialog
              currentCode={this.state.cloudLinkCode}
              onEnter={(code) => this.linkDevice(code)}
              onClose={() => this.hideDeviceLink()}
              unlink={() => this.unlinkDevice()}
              linkedMhdClient={this.state.linkedMhdClient}
              deviceLinkVerifying={this.state.deviceLinkVerifying}
            />
          </div>
          <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.hideDeviceLink(); }} className="screentopright" style={{transform: 'rotateZ(45deg)', lineHeight: '1em', userSelect:'none', margin: '-0.5em 0 0 -0.2em', position:'fixed', pointer: 'default'}}>
            <span style={{ fontSize: '60px'}}>+</span>
          </div>
        </div>}
        {/*<div className={"overlay" + (this.state.loginShown ? ' shown' : '')} onClick={() => this.setState({loginShown: null})}>
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            <LoginDialog
              onLogin={(username, password) => this.login(username, password)}
              loginError={this.state.loginError}
            />
            <br/><a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({loginShown: null}); }}>Close</a>
          </div>
            </div>*/}
      </div>
    );
  }
}

export default App;
