import React from 'react';
import qrcode from 'qrcode';
import Upload from './Upload';
import './SetUploader.css';

class SetUploader extends React.Component {
  state = {
    set: {},
    uploadState: 'none',
    abortController: null,
    progress: {},
    uploadId: null,
    updateTimer: null,
    stateLink: null,
    qrCode: null,
    setId: null,
    uploadSignature: false
  }

  componentDidMount() {
    this.setState({
      setId: this.props.id,
      set: this.props.set
    });

    this.startUpload(this.props.set);
  }

  componentWillUnmount() {
    this.abortUpload();
  }

  startUpload(set) {
    const uploadData = new FormData();
    const file = new Blob([set.data]);
    let size = file.size;

    uploadData.append('data', file, 'data');
    uploadData.append('metadata', JSON.stringify(set.metaData));

    let getUploadIdUrl = this.props.endPointGetUploadId;

    if (this.props.user && this.props.user.token) {
      getUploadIdUrl += `?user=${this.props.user.userid}&token=${this.props.user.token}`;
    }

    fetch(getUploadIdUrl)
      .then(response => response.json())
      .then(response => {
        const uploadId = response.id;

        const abortController = new AbortController();
        const abortSignal = abortController.signal

        fetch(`${this.props.endPointUpload}/${uploadId}?total=${size}&gzip=${set.gzip}`, {
          method: 'POST',
          body: uploadData,
          signal: abortSignal
        })
          .then(response => response.json())
          .then(response => {
            this.updateState(response);
            this.uploadFinished(response);
          })
          .catch(
            error => console.log(error, error.message)
          );

        this.setState({
          uploadId,
          uploadLink: response.url,
          updateTimer: setTimeout(() => this.updateState(), 16),
          abortController
        });
      }).catch(
        error => console.log(error, error.message)
      );

    this.setState({set});
  }

  uploadFinished(uploadInfo) {
    const stateLink = this.state.uploadLink;

    setTimeout(() => {
      qrcode.toDataURL(stateLink, { errorCorrectionLevel: 'L' }, (err, url) => {
        this.setState({qrCode: url, stateLink});
      });

      this.setState({
        progress: uploadInfo.progress,
        state: uploadInfo.state
      });
    });
  }

  clearUpdateTimer() {
    if (this.state.updateTimer != null) clearTimeout(this.state.updateTimer);
  }

  updateState() {
    if (!this.state.uploadId) return;
    this.clearUpdateTimer();

    fetch(`${this.props.endPointUpload}/${this.state.uploadId}`)
      .then(response => response.json())
      .then(response => {
        let timer = null;

        if (response.state === 'error') {
          this.props.onError();
        } else if (response.state === 'done') {
          this.props.onDone(this.state);
        }
        else {
          timer = setTimeout(() => this.updateState(), 250);
        }

        this.setState({
          progress: response.progress,
          updateTimer: timer,
          uploadState: response.state,
          error: response.error
        });
      })
      .catch(e => console.log(e));
  }

  abortUpload() {
    if (this.state.abortController != null) this.state.abortController.abort();
    if (this.state.updateTimer != null) clearTimeout(this.state.updateTimer);

    this.setState({
      set: {},
      abortController: null,
      updateTimer: null,
      uploadState: 'none',
      qrCode: null,
      setId: null
    });
  }

  render() {
    return (
      <div className="setuploader">
        {this.state.setId && <Upload 
          linkedMhdClient={this.props.linkedMhdClient}
          onOpenClick={() => this.props.onOpenClick(this.state.qrCode, this.state.stateLink)}
          id={this.state.setId}
          uploadState={this.state.uploadState}
          stateLink={this.state.stateLink}
          set={this.state.set}
          qrCode={this.state.qrCode}
          error={this.state.error}
          onAbort={() => this.abortUpload()}
          progress={this.state.progress} />}
      </div>
    );
  }
}

export default SetUploader;