

export default () => {
  const _importScripts = self['importScripts'] ? self['importScripts'] : () => {}; // eslint-disable-line no-restricted-globals

	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
    if (!e) return;

    let daikon = null, pako = null;
    if (e.data.url) {
      postMessage({daikon: daikon ? 'loaded' : 'notloaded'});
    }
    if (e.data.items) {
      if (!daikon) {
        _importScripts('https://cdn.jsdelivr.net/npm/daikon@1.2.42/release/current/daikon-min.js');
        daikon = self['daikon'];  // eslint-disable-line no-restricted-globals
      }
      if (!pako) {
        _importScripts('https://cdnjs.cloudflare.com/ajax/libs/pako/1.0.10/pako_deflate.min.js');
        pako = self['pako'];  // eslint-disable-line no-restricted-globals
      }

      let setCount = 0;
      const files = {};
      const sets = {};
      const minMax = {};
      const invalids = [];
      let toProcess = e.data.items.length;

      e.data.items.forEach((file, ix) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          toProcess--;

          const arrayBuffer = e.target.result;
          const data = new DataView(arrayBuffer);
          const image = daikon.Series.parseImage(data);

          if (image === null || !image.hasPixelData()) {
            invalids.push(file);
          }
          else {
            let id = image.getSeriesId();

            let numberOfFrames = image.getTag(0x0028, 0x0008);
            if (numberOfFrames && numberOfFrames.value && numberOfFrames.value.length) {
              id = image.getTag(0x0008, 0x0018).value[0];
            }

            const pixelData = image.getInterpretedData(false, true);

            if (!(id in sets)) {
              sets[id] = new daikon.Series();
              files[id] = [];
              minMax[id] = {min: pixelData.min, max: pixelData.max};
              setCount++;
            }
  
            minMax[id] = {min: Math.min(pixelData.min, minMax[id].min), max: Math.max(pixelData.max, minMax[id].max)};
            files[id].push(file);
            sets[id].addImage(image);

            postMessage({stat:{leftFiles: toProcess}});
          }
          
          if (toProcess === 0) {
            const setIds = Object.keys(sets);

            console.log(setIds);

            if (!setIds.length) {
              postMessage({length: invalids.length, invalids: invalids});
              return;
            }

            let setIdsToTransfer = setIds.length;

            postMessage({stat: {leftSets: setIdsToTransfer}});

            setIds.forEach(id => {
              const series = sets[id];

              series.buildSeries();

              const firstImage = series.images[0];
              const lastImage = series.images[series.images.length - 1];
              /*
              if (firstImage.getPixelSpacing() === null || firstImage.getImagePosition() === null) {
                console.log("Cannot find PixelSpacing or ImagePosition, skipping dataset");
                setIdsToTransfer--;
                postMessage({stat: {leftSets: setIdsToTransfer}});

                if (setIdsToTransfer === 0) {
                  postMessage({length: invalids.length, invalids: invalids});
                }

                return;
              }*/
            
              try {
                series.concatenateImageData(null, (imageData) => {
                  const bodyPartTag = lastImage.getTag(0x0018, 0x0015);

                  let numberOfFrames = firstImage.getTag(0x0028, 0x0008);
                  numberOfFrames = numberOfFrames && numberOfFrames.value && numberOfFrames.value.length ? numberOfFrames.value[0] : null;
                  const frameRate = firstImage.getTag(0x0008,0x2144);
                  const frameTimes = firstImage.getTag(0x0018, 0x1065);
  
                  const metaData = {
                    ImagePositionPatientFirst: lastImage.getImagePosition() || [-239.0224609375,-413.5224609375,1121.8],
                    ImagePositionPatientLast: firstImage.getImagePosition() || [-239.0224609375,-413.5224609375,-379],
                    SliceThickness: firstImage.getSliceThickness(),
                    ImageOrientation: firstImage.getImageDirections() || [1,0,0,0,1,0],
                    PixelSpacing: firstImage.getPixelSpacing() || [0.955078125,0.955078125],
                    PhotometricInterpretation: firstImage.getPhotometricInterpretation(),
                    BitsAllocated: firstImage.getBitsAllocated(),
                    Modality: firstImage.getModality(),
                    BodyPart: bodyPartTag && bodyPartTag.value && bodyPartTag.value.length ? bodyPartTag.value[0] : "",
                    orientation: firstImage.getOrientation() || "XYZ--+",
                    width: firstImage.getCols(),
                    height: firstImage.getRows(),
                    depth: series.images.length,
                    min: minMax[id].min,
                    max: minMax[id].max,
                    numberOfFrames: numberOfFrames,
                    frameRate: frameRate && frameRate.value && frameRate.value.length ? frameRate.value[0] : null,
                    frameTimes: frameTimes && frameTimes.value && frameTimes.value.length ? frameTimes.value : null
                  };
  
                  metaData.PhysicalSize = getPhysicalSize(metaData);
                  metaData.ScanDirection = getScanDirection(metaData);

                  const newId = Math.floor(Math.random() * 100000 + 10000);

                  console.log("Processing", newId, files[id]);

                  const PixelByteSize = metaData.BitsAllocated / 8;
                  let dataview = null;

                  if (PixelByteSize == 2) {
                    dataview = new Uint16Array(imageData);
                  }
                  else {
                    dataview = new Uint8Array(imageData);
                  }

                  // mirrorAxises(dataview, metaData);

                  const imageDataCompressed = pako.gzip(new Uint8Array(dataview.buffer)).buffer;

                  console.log(metaData);
                  /*
                  const imageData16 = new Int16Array(imageData);
                  const imageData8 = new ArrayBuffer(imageData.byteLength / 2);
  
                  for (var i = 0; i < imageData16.lengh; i++) {
                    imageData8[i] = Math.floor(imageData16[i] / 4096 * 256);
                  }
                  */
                  console.time("ArrayBuffer transfer");
                  postMessage({
                    set: newId, length: series.images.length, gzip: true, imageData: imageDataCompressed, rawImageData: imageData, metaData: metaData
                  }, [imageDataCompressed, imageData]);
                  console.timeEnd("ArrayBuffer transfer");
  
                  setIdsToTransfer--;
                  postMessage({stat: {leftSets: setIdsToTransfer}});
  
                  if (setIdsToTransfer === 0) {
                    postMessage({length: invalids.length, invalids: invalids});
                  }
                });
              }
              catch (e) {
                setIdsToTransfer--;
                postMessage({stat: {leftSets: setIdsToTransfer}});

                if (setIdsToTransfer === 0) {
                  postMessage({length: invalids.length, invalids: invalids});
                }
              }
            });
          }
        };
  
        reader.readAsArrayBuffer(file);
      });

      postMessage({received: e.data.items.length});
    }
  })

  function getScanDirection(set) { 
    let disZ = 0;
    let dis = {
        x: Math.abs(set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0]),
        y: Math.abs(set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1]),
        z: Math.abs(set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2])
    };
    let scanDirection;

    let maxd = Math.max(dis.x, Math.max(dis.y, dis.z));
    if (dis.x > 0 && dis.y > 0 && dis.z > 0) {
        if (dis.x == maxd) {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
        else if (dis.y == maxd) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.x > 0 && dis.y > 0) {
        if (dis.y > dis.x) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
    }
    else if (dis.y > 0 && dis.z > 0) {
        if (dis.y > dis.z) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.x > 0 && dis.z > 0) {
        if (dis.x > dis.z) {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.z > 0) {
        disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
        if (disZ > 0)	scanDirection = [0, 0, 1];
        else			scanDirection = [0, 0, -1];
    }
    else if (dis.y > 0) {
        disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
        if (disZ > 0)	scanDirection = [0, 1, 0];
        else			scanDirection = [0, -1, 0];
    }
    else {
        disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
        if (disZ > 0)	scanDirection = [1, 0, 0];
        else			scanDirection = [-1, 0, 0];
    }

    return scanDirection;
  }

  function getPhysicalSize(metadata) {
    var volScale = [metadata.PixelSpacing[0] * metadata.width / 1000, metadata.PixelSpacing[1] * metadata.height / 1000, 1];
    volScale[2] = Math.sqrt(
      Math.pow(metadata.ImagePositionPatientLast[0] - metadata.ImagePositionPatientFirst[0], 2) +
      Math.pow(metadata.ImagePositionPatientLast[1] - metadata.ImagePositionPatientFirst[1], 2) +
      Math.pow(metadata.ImagePositionPatientLast[2] - metadata.ImagePositionPatientFirst[2], 2)
    ) / 1000;
  
    if (volScale[2] === 0) {
      volScale[2] = volScale[0] / metadata.width;
    }

    return volScale;
  }

  function mirrorAxises(volume, metadata) {
    const components = (metadata.PhotometricInterpretation == "RGB") ? 3 : 1;
    const totalVolumeLength = metadata.width * metadata.height * metadata.depth * components;

    if (components === 1) {
      if (metadata.orientation[3] == '+') {
        console.log("Mirroring X");
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = i % metadata.width;
          if (flipRelIndexA < metadata.width / 2) {
            flipIndexB = (i - flipRelIndexA) + (metadata.width - flipRelIndexA - 1);
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
      if (metadata.orientation[4] == '+') {
        console.log("Mirroring Y");
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = Math.floor(i / metadata.width);
          if (flipRelIndexA < metadata.height / 2) {
            flipIndexB = (i - flipRelIndexA * metadata.width) + (metadata.height - flipRelIndexA - 1) * metadata.width;
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
      if (metadata.orientation[5] == '+') {
        console.log("Mirroring Z");
        let plane = metadata.width * metadata.height;
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = Math.floor(i / plane);
          if (flipRelIndexA < metadata.depth / 2) {
            flipIndexB = (i - flipRelIndexA * plane) + (metadata.depth - flipRelIndexA - 1) * plane;
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
    }
  }
}