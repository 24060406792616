import React from 'react';

class Visualizer extends React.Component {
  state = {
    inView: false
  }
  
  constructor(props) {
    super(props);
    this.frame = React.createRef();
    this.container = React.createRef();
  }

  componentDidMount() {
    this.checkViewport = () => {
      if (this.container && this.container.current) {
        var bounding = this.container.current.getBoundingClientRect();
        if (
          bounding.top >= 0 && bounding.top <= (window.innerHeight || document.documentElement.clientHeight) ||
          bounding.bottom >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) this.setState({inView: true})
        else this.setState({inView: false});
      }
    };

    window.addEventListener('scroll', this.checkViewport);
    this.checkViewport();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkViewport);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.setId != this.props.setId || this.state.inView != nextState.inView);
  }

  sendData() {
    const frame = this.frame.current;
    frame.contentWindow.postMessage({
      dimensions: [this.props.set.metaData.width, this.props.set.metaData.height, this.props.set.metaData.depth],
      buffer: this.props.set.rawImageData,
      metadata: this.props.set.metaData
    }, [this.props.set.data]);
  }

  render() {
    const time = Date.now();
    return (
      <div ref={this.container} style={{minWidth: '1px', flex:1, minHeight: '1px'}}>
        {this.state.inView &&<iframe ref={this.frame} onLoad={() => {this.sendData()}} src={"raycaster/renderer.html?now=" + time}
          style={{width:'100%', height:'100%', borderWidth: 0, overflow:'hidden'}} allowtransparency="true" border="0" width="250" height="250">
        </iframe>}
      </div>
    );
  }
}

export default Visualizer;